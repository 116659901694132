import React from "react"
import Button from '../button'
import "./boat-details.css"

const BoatDetails = ({ title, acf }) => {
  const cta = {url:'/book', target:''}
  return (
    <section className="block-v pv-[5vw] bg-dark-blue" >
      <div className="container-v">
      <div className="md:flex md:space-x-[6vw] justify-between" >
        <div className="md:w-1/2">
          {title &&
            <h2 className="text-[6vw] md:text-lg uppercase" >{title}</h2>
          }
          <div className="p-lrg">
            {acf.capacity} | {acf.crew} Crew Members | {acf.startingPrice}
          </div>
          {acf?.description &&
            <div className="mt-[1.5vw] p-lrg p-styles" dangerouslySetInnerHTML={{__html:acf.description}} />
          }

        <Button classes="mt-[2.5vw] bg-yellow text-dark-blue btn-lrg" cta={cta}>Book Now</Button>

        </div>
        <div className="md:w-[26vw] mt-[10vw] md:mt-[0]">
          <aside className="boat-links">
            <a href="/destinations" className="font-helvetica font-light uppercase underline">Explore Destinations</a>
            {acf.pdf &&
              <a href="" className="font-helvetica uppercase">Download PDF</a>
            }
          </aside>
          <aside className="boat-features font-helvetica font-light">
            <span className="uppercase feat-title">Features</span>
            <ul className="feature-list">
            {acf?.features?.map((item, index) => (
              <li>{item.feature}</li>
            ))}
            </ul>
          </aside>
        </div>
      </div>
      </div>
    </section>
  )
}

export default BoatDetails
